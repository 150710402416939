import React from "react";
import "./OnboardingHeader.css";
import clsx from "clsx";
import {HeaderUserMenu, QuickLinks, ThemeModeSwitcher} from "../../_rightcom/partials";
import {KTSVG, toAbsoluteUrl} from "../../_rightcom/helpers";
import {useLayout} from "../../_rightcom/layout/core";
import {Link} from "react-router-dom";
import {Header} from "../../_rightcom/layout/components/header/Header";
import {DefaultTitle} from "../../_rightcom/layout/components/header/page-title/DefaultTitle";
import {Topbar} from "../../_rightcom/layout/components/header/Topbar";

const toolbarButtonMarginClass = 'ms-1 ms-lg-8 ms-4',
	toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
	toolbarButtonIconSizeClass = 'svg-icon-1'
const OnboardingHeader = (props: any) => {
	const {config, classes, attributes} = useLayout()
	const {header, aside} = config

	return (
		<div
			id='kt_header'
			className={clsx('header', classes.header.join(' '),
				'align-items-stretch border-bottom border-end border-start')}
			{...attributes.headerMenu}
			style={{
				borderBottom: 'solid 0.75px #ddd !important'
			}}
		>
			<div
				className={clsx(
					classes.headerContainer.join(' '),
					'd-flex align-items-stretch justify-content-between'
				)}
			>

				<div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
					<Link to='/' className=''>
						<img alt='Logo' src={toAbsoluteUrl('/RightDesk_logo.svg')} className='h-30px'/>
					</Link>
				</div>

				{/* begin::Wrapper */}
				<div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
					{/* begin::Navbar */}
					{header.left === 'menu' && (
						<div className='d-flex align-items-stretch' id='kt_header_nav'>
							<Header/>
						</div>
					)}

					{header.left === 'page-title' && (
						<div className='d-flex align-items-center' id='kt_header_nav'>
							<DefaultTitle/>
						</div>
					)}
					<div className='d-flex align-items-center' id='kt_header_nav'>
						<DefaultTitle/>
					</div>
					<div className='d-flex align-items-stretch flex-shrink-0'>
						<Topbar/>
					</div>
				</div>
				{/* end::Wrapper */}
			</div>
		</div>
	);
};
export default OnboardingHeader;
