import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_rightcom/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_rightcom/assets/ts/_utils'
import {WithChildren} from '../../_rightcom/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import SamplePage from "../../pages/SamplePage";
import Onboarding from "../../pages/Onboarding";
import Page401 from "../../pages/401";
import MainWrapper from "../../wrappers/MainWrapper";
import AllDevices from '../../wrappers/AllDevices'
import RightPlayer from '../../wrappers/RightPlayer'
import RightQ from '../../wrappers/RightQ'
import Test from '../../wrappers/Test'
import AllTable from '../../wrappers/AllTable'

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
	
	



	return (
		<Routes>
			<Route path='onboarding' element={<Onboarding/>}/>
			<Route path='unauthorized' element={<Page401/>}/>
			<Route element={<MasterLayout/>}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
				{/* Pages */}
				<Route path='dashboard' element={<DashboardWrapper/>}/>
				<Route path='alldevices' element={<AllDevices />}/>
				<Route path='rightplayer' element={<RightPlayer />}/>
				<Route path='rightq' element={<RightQ/>}/>
				<Route path='main' element={<MainWrapper/>}/>
				<Route path='builder' element={<BuilderPageWrapper/>}/>
				<Route path='samplepage' element={<SamplePage/>}/>
				<Route path='menu-test' element={<MenuTestPage/>}/>
				{/* Lazy Modules */}
				<Route
					path='crafted/pages/profile/*'
					element={
						<SuspensedView>
							<ProfilePage/>
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/pages/wizards/*'
					element={
						<SuspensedView>
							<WizardsPage/>
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/widgets/*'
					element={
						<SuspensedView>
							<WidgetsPage/>
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/account/*'
					element={
						<SuspensedView>
							<AccountPage/>
						</SuspensedView>
					}
				/>
				<Route
					path='apps/chat/*'
					element={
						<SuspensedView>
							<ChatPage/>
						</SuspensedView>
					}
				/>
				<Route
					path='apps/user-management/*'
					element={
						<SuspensedView>
							<UsersPage/>
						</SuspensedView>
					}
				/>
				{/* Page Not Found */}
				<Route path='*' element={<Navigate to='/error/404'/>}/>
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({
	children
}
) =>
{
	const baseColor = getCSSVariableValue('--kt-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export
{
	PrivateRoutes
}
