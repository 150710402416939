// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Onboarding {}
.Onboarding .header{
    left: 0!important;
}
.Onboarding main {
    min-height: calc(100vh - 180px);
    margin-top: 60px;
}

@media screen and (min-width: 768px){
    .Onboarding main {
        min-height: calc(100vh - 160px);
        margin-top: 60px;
    }
}

.Onboarding .progress-bar{
    height: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Onboarding.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,iBAAiB;AACrB;AACA;IACI,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI;QACI,+BAA+B;QAC/B,gBAAgB;IACpB;AACJ;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".Onboarding {}\n.Onboarding .header{\n    left: 0!important;\n}\n.Onboarding main {\n    min-height: calc(100vh - 180px);\n    margin-top: 60px;\n}\n\n@media screen and (min-width: 768px){\n    .Onboarding main {\n        min-height: calc(100vh - 160px);\n        margin-top: 60px;\n    }\n}\n\n.Onboarding .progress-bar{\n    height: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
