import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import * as helpers from "../../utils/helpers"
import Page401 from '../../pages/401/401'

import {
  DrawerMessenger,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return helpers.checkAliasAppidSidSubdomainUser() ? (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div className='_wrapper d-flex flex-column flex-row-fluid' id='rc_wrapper'>
            <HeaderWrapper />
            <div style={{marginTop: 55}}/>
						<Toolbar/>
            <div id='rc_content'
						     className='container-fluid content d-flex flex-column flex-column-fluid'>
							<div className='post d-flex flex-column-fluid' id='rc_post'>
                <div className='container-fluid'>
                <div className='d-flex flex-column flex-lg-row '>
                <div className='flex-column flex-lg-row-auto  mb-10 marM'><AsideDefault /></div>
                <div className='flex-lg-row-fluid ms-lg-4 '>
                    <Content>
                  <Outlet />
                </Content>
                </div>
                </div>
                </div>
                
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        {/*<ActivityDrawer />*/}
        {/*<RightToolbar />*/}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        {/*<UpgradePlan />*/}
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  ) : <Page401/>
}

export {MasterLayout}
