/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const intl = useIntl()

  const {classes} = useLayout()
  return (
    <div className="footer py-4">
      <div  className="container" >
          <div  className="row justify-content-lg-between h-100" >
            <div  className="col-lg-8 align-self-center text-lg-left text-center" >
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                  <li className="menu-item pe-5"><a className='menu-link text-primary'  href="javascript:void(0)">&copy; {new Date().getFullYear()}</a></li>
                  <li className="menu-item ps-5 pe-5"><a style={{"color": "#808080"}} className='menu-link'  href="https://rightcom.com/platform">{intl.formatMessage({id: 'FOOTER.EXPLORE'})}</a></li>
                  <li className="menu-item align-self-center pb-lg-2 pb-0"><span style={{'fontSize' : "4px","color": "#808080"}} className="fas fa-circle"></span></li>
                  <li className="menu-item"><a style={{"color": "#808080"}} className='menu-link'  href="https://rightcom.com/">{intl.formatMessage({id: 'FOOTER.MORE'})}</a></li>
                  <li className="menu-item align-self-center pb-lg-2 pb-0"><span style={{'fontSize' : "4px","color": "#808080"}} className="fas fa-circle"></span></li>
                  <li className="menu-item"><a style={{"color": "#808080"}} className='menu-link'  href="#">{intl.formatMessage({id: 'FOOTER.HELP'})}</a></li>
                </ul>
            </div>
            <div  className="col-lg-4 align-self-center text-lg-end text-center" >
              <span style={{'fontSize' : "12px", "color": "#6C6C6C"}}>{intl.formatMessage({id: 'FOOTER.PRODUCT_OF'})} </span>
              <span  className="footer-logo">
                <a  href="https://rightcom.com/" rel="noopener noreferrer" target="_blank">
                  <img style={{'width' : "75px"}}  alt="brandicon" src="RightCom.svg" />
                </a>
              </span>
            </div>
          </div>
      </div>
    </div>


    
  )
}

export {Footer}
