import React, {useContext, useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {useIntl} from "react-intl";
import Table from "../../_rightcom/layout/components/table/table";
import MainWrapper from "../MainWrapper";
import AllTable from "../AllTable";

function MainSide() {

  return (
    <div>
      <AllTable />
    </div>
  )
}

export default MainSide