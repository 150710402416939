import React from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {Toolbar1} from './Toolbar1'
import {Link, useLocation} from "react-router-dom";
import '../header/HeaderWrapper.css'
import {useIntl} from 'react-intl'

const Toolbar = () => {
  const {config} = useLayout()
  const location = useLocation()
  const intl = useIntl()

  return (
    <div className='toolbar h-67px pt-5 pt-sm-4 pb-0 mb-5' id='rc_toolbar'>
		{/*<div className={"bg-white"}>*/}
			<div className="container bo">
				<div className="row">
					<div className='w-100 overflow-auto'>
						<ul className='nav nav-fill nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
							<li className='nav-item'>
								<Link
									className={
										`nav-link text-active-primary me-4 ms-4 d-inline-block p-3 ` +
										(location.pathname === ('/dashboard') && 'active')
									}
									to={`/dashboard${location.search}`}
								>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="16.471" height="14" viewBox="0 0 16.471 14">
  <path id="Icon_material-home" data-name="Icon material-home" d="M9.588,18.5V13.559h3.294V18.5H17V11.912h2.471L11.235,4.5,3,11.912H5.471V18.5Z" transform="translate(-3 -4.5)"  fill={location.pathname === ('/dashboard') ? "#0089E1" : "gray"}/>
</svg></span>
									<span className={"d-none d-sm-block d-md-inline "}>&nbsp;&nbsp; {intl.formatMessage({id: 'TOPBAR.HOME'})}</span>
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									className={
										`nav-link text-active-primary me-4 ms-4 d-inline-block p-3 ` +
										(location.pathname === ('/alldevices') && 'active')
									}
									to={`/alldevices${location.search}`}
								>
								<span><svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="14" viewBox="0 0 12.25 14">
  <path id="Icon_open-file" data-name="Icon open-file" d="M0,0V14H12.25V7h-7V0ZM7,0V5.25h5.25Z" fill={location.pathname === ('/alldevices') ? "#0089E1" : "gray"}/>
</svg>
</span>
									<span className={"d-none d-sm-block d-md-inline"}>&nbsp;&nbsp; {intl.formatMessage({id: 'TOPBAR.ALLDEVICES'})}</span>
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									className={
										`nav-link text-active-primary me-4 ms-4 d-inline-block p-3 ` +
										(location.pathname === ('/rightplayer') && 'active')
									}
									to={`/rightplayer${location.search}`}
								>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="14.562" height="13.647" viewBox="0 0 14.562 13.647">
  <g id="Group_14970" data-name="Group 14970" transform="translate(-456.052 -425.234)">
    <path id="Path_2471" data-name="Path 2471" d="M467.493,436.629a1.194,1.194,0,0,1-.784.313h-.028l-8.275,1.009c-.788-.018-1.425-.884-1.425-1.947v-7.895c0-1.062.637-1.928,1.425-1.947l8.247,1.006.056,0a1.193,1.193,0,0,1,.784.313l1.229.1a2.328,2.328,0,0,0-1.983-1.346l-8.251-1.006-.056,0a2.667,2.667,0,0,0-2.38,2.877v7.895a2.667,2.667,0,0,0,2.38,2.876h.028l8.279-1.01a2.328,2.328,0,0,0,1.983-1.346Z" transform="translate(0)" fill="#515151"/>
    <g id="Group_1614" data-name="Group 1614" transform="translate(458.384 427.119)">
      <path id="Path_2472" data-name="Path 2472" d="M463.979,440.314l9.248-.784a1.715,1.715,0,0,0,1.491-1.873v-4.564a1.715,1.715,0,0,0-1.491-1.873l-9.248-.784a1.715,1.715,0,0,0-1.491,1.873v6.132A1.715,1.715,0,0,0,463.979,440.314Zm1.869-6.32a.773.773,0,0,1,.671-.844l4.166.353a.773.773,0,0,1,.671.844V436.4a.773.773,0,0,1-.671.844l-4.166.353a.773.773,0,0,1-.671-.844Z" transform="translate(-462.488 -430.436)" fill="#47c431"/>
    </g>
  </g>
</svg>
</span>
									<span className={"d-none d-sm-block d-md-inline"}>&nbsp;&nbsp; RIGHTPLAYER </span>
								</Link>
							</li>
							{/*{(keycloak.realmAccess?.roles.includes("owner") || keycloak.realmAccess?.roles.includes("billing")) && <li className='nav-item'>*/}
							{/*	<Link*/}
							{/*		className={*/}
							{/*			`nav-link text-active-primary me-4 ms-4 d-inline-block p-3 ` +*/}
							{/*			(location.pathname === ('/billing') && 'active')*/}
							{/*		}*/}
							{/*		to='/billing'*/}
							{/*	>*/}
							{/*		<i className="bi bi-receipt-cutoff"></i>*/}
							{/*		<span className={"d-none d-sm-block d-md-inline"}>&nbsp;&nbsp; Billing </span>*/}
							{/*	</Link>*/}
							{/*</li>}*/}

							<li className='nav-item'>
								<Link
									className={
										`nav-link text-active-primary me-4 ms-4 d-inline-block p-3 ` +
										(location.pathname === ('/rightq') && 'active')
									}
									to={`/rightq${location.search}`}
								>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="34" height="23.896" viewBox="0 0 34 23.896">
  <g id="Group_6827" data-name="Group 6827" transform="translate(0 0)">
    <path id="Path_7749" data-name="Path 7749" d="M8.471,16.228A13.6,13.6,0,0,1,15,4.655a12.405,12.405,0,0,0-3-.375A11.932,11.932,0,0,0,0,16.212H0A11.948,11.948,0,0,0,11.948,28.16h0a12.405,12.405,0,0,0,3-.375,13.58,13.58,0,0,1-6.48-11.556Z" transform="translate(0 -4.28)" fill="#47c431"/>
    <circle id="Ellipse_665" data-name="Ellipse 665" cx="11.948" cy="11.948" r="11.948" transform="translate(10.104 0)" fill="#3487ea"/>
    <g id="Group_6826" data-name="Group 6826" transform="translate(16.453 6.105)">
      <circle id="Ellipse_398" data-name="Ellipse 398" cx="2.628" cy="2.628" r="2.628" transform="translate(2.971)" fill="#fff"/>
      <path id="Path_4365" data-name="Path 4365" d="M10.08,16.741h11.2a5.354,5.354,0,0,0-5.664-4.9A5.256,5.256,0,0,0,10.08,16.741Z" transform="translate(-10.08 -5.609)" fill="#fff"/>
    </g>
  </g>
</svg></span>
									<span className={"d-none d-sm-block d-md-inline"}>&nbsp;&nbsp; RIGHTQ </span>
								</Link>
							</li>
						
						</ul>
					</div>
				</div>
			</div>
		</div>
  )
}

export {Toolbar}
