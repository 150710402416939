/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { AppItem } from './AppItem'

const AppList = (props: any) => {
  return (
    props.lists.map((list: any, index: any) => (
        <div key={index} className={`card ${props.className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-muted fs-3 mb-1'>{list.name}</span>
                </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

                {/* begin::AppItem */}
                {list.data.map((item: Array<any>, index: any) => <AppItem key={index} item={item} />)}
                {/* end::AppItem */}

            </div>
            {/* begin::Body */}
        </div>
    ))
  )
}

export {AppList}
