import React, { FC } from 'react';
import {useIntl} from 'react-intl'
import '../header/HeaderWrapper.css'

interface cardDefaultProps {
  data: any;
  prodName:string;
  prodImg:string;
}

const Card: FC<cardDefaultProps> = ({ data, prodName, prodImg}) => {
  const intl = useIntl()

  const action = prodName === 'RightQ' ? intl.formatMessage({id: 'SIDEBAR.TICKETS_PRINTED'}) : intl.formatMessage({id: 'SIDEBAR.TICKETS_DISPLAYED'})
  return (
    <div>

        {/*begin::Mixed Widget 9*/}
<div className="card cardD h-lg-50 mt-5 ">
  {/*begin::Body*/}
  <div className="card-body d-flex flex-column ">
    <div className="flex-grow-1">
      {/*begin::Info*/}
      <div className="d-flex align-items-center pe-2 mb-5">
      <div className="symbol h-32px w-34px">
          <span className="symbol-label bg-light">
            <img src={prodImg} className="h-50 align-self-center mi" alt = "" />
          </span>
        </div>
        <span className="fw-bold fs-5 flex-grow-1">{prodName}</span>
        
      </div>
      {/*end::Info*/}
      <hr style={{color:'grey'}}/>
      {/*begin::Link*/}
      <div className=" fs-4 Manrope ft">{data.data.total_device}</div>
      {/*end::Link*/}
      {/*begin::Desc*/}
      <p className="ftt">{intl.formatMessage({id: 'SIDEBAR.TOTAL_DEVICES'})}</p>
      {/*end::Desc*/}
       {/*begin::Link*/}
       <div className=" fs-4 Manrope ft">{data.data.total_device_working}</div>
      {/*end::Link*/}
      {/*begin::Desc*/}
      <p className="ftt">{intl.formatMessage({id: 'SIDEBAR.TOTAL_WORKING'})}</p>
      {/*end::Desc*/}
       {/*begin::Link*/}
       <div className=" fs-4 Manrope ft">{data.data.total_ticket}</div>
      {/*end::Link*/}
      {/*begin::Desc*/}
      <p className="ftt">{intl.formatMessage({id: 'SIDEBAR.TOTAL_TICKETS'})} {action}</p>
      {/*end::Desc*/}
    </div>
  </div>
  {/*end::Body*/}
</div>
{/*end::Mixed Widget 9*/}

    </div>
  )
}

export default Card