/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as helpers from "../../../utils/helpers"
import {AppFilter} from './components'
import {AppList} from './components'
import './dashboard.css'
import moment from 'moment'
import {useLocation} from "react-router-dom";
import { isNotEmpty } from '../../../_rightcom/helpers';

const DashboardPage = () => {
  const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  const lists = reduxStore.homeData
  
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const alias = params.get('alias');
  const app_id = params.get('app_id');
  const sid = params.get('sid');
  const subdomain = params.get('subdomain');
  const user = params.get('user');

  const session_date_from = sessionStorage.getItem("date_from");
  const session_date_to = sessionStorage.getItem("date_to");

  const dispatch = useDispatch();
  useEffect(()=>{
    // if empty session date_from and date_to, dashboard useEffect fired here, else in AppFilter Component
    if(!isNotEmpty(session_date_from) && !isNotEmpty(session_date_to)) {
      let data = {
        "filter": {
          "app_code": [
            "RP",
            "RQ"
          ],
          "from": moment().format('YYYY-MM-DD').toString(),
          "to": moment().format('YYYY-MM-DD').toString(),
          "alias": alias,
          "app_id": app_id,
          "sid": sid,
          "subdomain": subdomain,
          "user": user
        }
      };
      helpers.getHomeData(dispatch, data)
    }

  }, [dispatch, alias, app_id, sid, subdomain, user])

  return <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-12'>
      <div className='col-xl-12'>
        <AppFilter />
        <AppList className='card-bordered mb-5' lists={lists} />
      </div>
    </div>
    {/* end::Row */}
  </>
}


const DashboardWrapper = () => {
  return (
    <div style={{marginTop: 0}}>  
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
