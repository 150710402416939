/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useLocation} from "react-router-dom";
import {useIntl} from 'react-intl'

const AppItem = (props: any) => {
  const intl = useIntl()
  const location = useLocation()
  const item = props.item
  let linkText = '';

  if (item.type === 'android'|| item.type === 'windows' || item.type === 'linux' ) {
    linkText = '/rightplayer';
  } else {
    linkText = '/rightq';
  }

  const action = linkText === '/rightq' ? intl.formatMessage({id: 'CARD_ITEM.TICKETS_PRINTED'}) : intl.formatMessage({id: 'CARD_ITEM.TICKETS_DISPLAYED'})

  return (
    <div className="card card-bordered py-2 mb-3">
        {/* begin::AppItem */}
            <div className="row px-5 py-3">
                <div className="col-lg-1 col-sm-6 text-center align-self-center mb-lg-0 mb-4">
                    <img className="img-fluid" src={`/media/svg/app/${item.type}.svg`}/>
                </div>
                <div className="col-lg-2 col-sm-6 text-muted align-self-center text-center mb-lg-0 mb-4">
                  {item.full_type}
                </div>
                <div className="col-lg-2 col-sm-6 align-self-center mb-lg-0 mb-4 text-lg-start text-center">
                    <h3 className="fw-bold">{item.total_device}</h3>
                    <p className="text-uppercase text-muted">{intl.formatMessage({id: 'CARD_ITEM.TOTAL_DEVICES'})}</p>
                </div>
                <div className="col-lg-2 col-sm-6 align-self-center mb-lg-0 mb-4 text-lg-start text-center">
                    <h3 className="fw-bold">{item.total_device_working}</h3>
                    <p className="text-uppercase text-muted">{intl.formatMessage({id: 'CARD_ITEM.TOTAL_WORKING'})}</p>
                </div>
                <div className="col-lg-3 col-sm-6 align-self-center mb-lg-0 mb-4 text-lg-start text-center">
                    <h3 className="fw-bold">{item.total_ticket}</h3>
                    <p className="text-uppercase text-muted">{intl.formatMessage({id: 'CARD_ITEM.TOTAL_TICKETS'})} {action}</p>
                </div>
                <div className="col-lg-1 col-sm-6 align-self-center mb-lg-0 mb-4 text-lg-start text-center">
                    <Link className="text-decoration-none" to={`${linkText}${location.search}`}>{intl.formatMessage({id: 'CARD_ITEM.VIEW_ALL'})}</Link>
                </div>
            </div>
        {/* end::AppItem */}
    </div>
  )
}

export {AppItem}
