// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root > .unauthorized {
    background-image: linear-gradient(126deg, rgb(255, 255, 255) 62%, rgba(0, 137, 225, 0.5) 100%) !important;
}

.langSelect {
    font: normal normal 600 16px/22px Source Sans Pro;
    border: 1px solid #000 !important;
    padding-top: 3px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-left: 5px;
    outline: none;
    border: none !important;
    box-shadow: none !important;
}

select {
    background-color: transparent;
}

.BtnHomePage, .BtnHomePage:hover, .BtnHomePage:active, .BtnHomePage:focus {
    background: #0089E1 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
    opacity: 1;
    font: normal normal bold 12px/17px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    text-transform: uppercase;
    opacity: 1;
    padding: 20px 70px;
    box-shadow: none !important;
    /* max-height: 54px; */
}

.pleaseClickText {
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #373737;
    opacity: 0.71;
    margin-top: 50px;
    margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/401/401.css"],"names":[],"mappings":"AAAA;IACI,yGAAyG;AAC7G;;AAEA;IACI,iDAAiD;IACjD,iCAAiC;IACjC,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IAGvB,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,0DAA0D;IAC1D,kBAAkB;IAClB,UAAU;IACV,kDAAkD;IAClD,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,UAAU;IACV,kBAAkB;IAClB,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,oDAAoD;IACpD,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["#root > .unauthorized {\n    background-image: linear-gradient(126deg, rgb(255, 255, 255) 62%, rgba(0, 137, 225, 0.5) 100%) !important;\n}\n\n.langSelect {\n    font: normal normal 600 16px/22px Source Sans Pro;\n    border: 1px solid #000 !important;\n    padding-top: 3px;\n    padding-right: 5px;\n    padding-bottom: 3px;\n    padding-left: 5px;\n    outline: none;\n    border: none !important;\n    -webkit-box-shadow: none !important;\n    -moz-box-shadow: none !important;\n    box-shadow: none !important;\n}\n\nselect {\n    background-color: transparent;\n}\n\n.BtnHomePage, .BtnHomePage:hover, .BtnHomePage:active, .BtnHomePage:focus {\n    background: #0089E1 0% 0% no-repeat padding-box !important;\n    border-radius: 5px;\n    opacity: 1;\n    font: normal normal bold 12px/17px Source Sans Pro;\n    letter-spacing: 0px;\n    color: #FFFFFF !important;\n    text-transform: uppercase;\n    opacity: 1;\n    padding: 20px 70px;\n    box-shadow: none !important;\n    /* max-height: 54px; */\n}\n\n.pleaseClickText {\n    font: normal normal normal 18px/25px Source Sans Pro;\n    letter-spacing: 0px;\n    color: #373737;\n    opacity: 0.71;\n    margin-top: 50px;\n    margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
