import axios from "axios";
import { isNotEmpty } from "../_rightcom/helpers";

const constant = require("../config");

//axios interceptors
axios.interceptors.request.use(function (config) {
    const location = window.location
    const urlParams = new URLSearchParams(location.search);
    
    const aliasValue = urlParams.get('alias')
    const appidValue = urlParams.get('app_id')
    const sidValue = urlParams.get('sid')

    config.params = {...config.params, alias: aliasValue}
    config.params = {...config.params, app_id: appidValue}
    config.params = {...config.params, sid: sidValue}

    config.headers = {
        'X-Parse-Application-Id': constant.APP_ID,
        'X-Parse-REST-API-Key': constant.REST_API_KEY,
        'Content-Type': 'application/json'
    }
    
    return config;
}, function (error) {
    return Promise.reject(error);
});

//Middleware for 401 or 404 response
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //let error_message = error.response.data

    if(error === undefined) {
        window.location.href="/unauthorized"
        return
    }

    if(error['response'] === undefined) {
        window.location.href="/unauthorized"
        return
    }

    if(error['response']['status'] === undefined) {
        window.location.href="/unauthorized"
        return
    }

    let status = error.response.status
    if(status === 401) {
        window.location.href="/unauthorized"
    }

});

export function getHomeData(dispatch, data = {}) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${constant.SERVER_URL}/functions/home`,
        data: JSON.stringify(data)
    };

    return axios.request(config)
        .then(json => {
            if (json !== undefined && json['data'] !== undefined) {
                const statisticRightPlayer = json.data.result.total.filter(t => t.code === 'RP')[0]
                const statisticRightQ = json.data.result.total.filter(t => t.code === 'RQ')[0]
                dispatch({ type: "SET", key: 'statisticRightPlayer', payload: statisticRightPlayer });
                dispatch({ type: "SET", key: 'statisticRightQ', payload: statisticRightQ });
                dispatch({ type: "SET", key: 'homeData', payload: json.data.result.data });
                dispatch({ type: "SET", key: 'companies', payload: json.data.result.company });
                dispatch({ type: "SET", key: 'username', payload: json.data.result.username });
            }
        });
}

export function getDeviceData(dispatch, data = {}) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${constant.SERVER_URL}/functions/device`,
        data: JSON.stringify(data)
    };

    return axios.request(config)
        .then(json => {
            if (json !== undefined && json['data'] !== undefined) {
                const statisticRightPlayer = json.data.result.total.filter(t => t.code === 'RP')[0]
                const statisticRightQ = json.data.result.total.filter(t => t.code === 'RQ')[0]
                if(isNotEmpty(statisticRightPlayer)) {
                    dispatch({ type: "SET", key: 'statisticRightPlayer', payload: statisticRightPlayer });
                } 
    
                if(isNotEmpty(statisticRightQ)) {
                    dispatch({ type: "SET", key: 'statisticRightQ', payload: statisticRightQ });
                }
                
                dispatch({ type: "SET", key: 'deviceData', payload: json.data.result.data });
                dispatch({ type: "SET", key: 'companies', payload: json.data.result.company });
                dispatch({ type: "SET", key: 'username', payload: json.data.result.username });
            }
        });
}

export function getOfficesList(dispatch, data = {}) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${constant.SERVER_URL}/functions/office`,
        data: JSON.stringify(data)
    };

    return axios.request(config)
        .then(json => {
            if (json !== undefined && json['data'] !== undefined) {
                dispatch({ type: "SET", key: 'officeList', payload: json.data.result });
            } 
        });
}

export function checkAliasAppidSidSubdomainUser() {
    const location = window.location
    const urlParams = new URLSearchParams(location.search);
    
    const aliasValue = urlParams.get('alias')
    const appidValue = urlParams.get('app_id')
    const sidValue = urlParams.get('sid')
    const subdomainValue = urlParams.get('subdomain')
    const userValue = urlParams.get('user')

    /*if(!isNotEmpty(aliasValue) || !isNotEmpty(appidValue) || !isNotEmpty(sidValue)) {
        window.location.href="/unauthorized"
    }*/

    return (isNotEmpty(aliasValue) && isNotEmpty(appidValue) && isNotEmpty(sidValue)) ||
    (isNotEmpty(subdomainValue) && isNotEmpty(userValue) && isNotEmpty(sidValue))
}
