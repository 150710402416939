const CONFIG = {
    API: {
        BASE_PATH: `${process.env.REACT_APP_PARSE_SERVER_PROTOCOL}://${process.env.REACT_APP_PARSE_SERVER_DOMAINE_OR_IP}`,
        BASE_ENDPOINT: `/${process.env.REACT_APP_PARSE_SERVER_MOUNT_PATH}`
    },
    APP_ID: process.env.REACT_APP_X_PARSE_APPLICATION_ID,
    JAVASCRIPT_KEY: process.env.REACT_APP_JAVASCRIPT_KEY,
    REST_API_KEY: process.env.REACT_APP_X_PARSE_REST_API_KEY,
    DEVICE_TYPES: [
        {
            'name': 'RightPlayer Android',
            'value': 'android',
        },
        {
            'name': 'RightPlayer Windows',
            'value': 'windows',
        },
        {
            'name': 'RightPlayer Linux',
            'value': 'linux',
        },
        {
            'name': 'RightQ Welcomer',
            'value': 'welcomer',
        },
        {
            'name': 'RightQ Kiosk',
            'value': 'kiosk',
        },
    ],
    DEVICE_TYPES_RP: [
        {
            'name': 'RightPlayer Android',
            'value': 'android',
        },
        {
            'name': 'RightPlayer Windows',
            'value': 'windows',
        },
        {
            'name': 'RightPlayer Linux',
            'value': 'linux',
        }
    ],
    DEVICE_TYPES_RQ: [
        {
            'name': 'RightQ Welcomer',
            'value': 'welcomer',
        },
        {
            'name': 'RightQ Kiosk',
            'value': 'kiosk',
        }
    ]
}

module.exports = {
    APP_ID: CONFIG.APP_ID,
    JAVASCRIPT_KEY: CONFIG.JAVASCRIPT_KEY,
    REST_API_KEY: CONFIG.REST_API_KEY,
    SERVER_URL: `${CONFIG.API.BASE_PATH}${CONFIG.API.BASE_ENDPOINT}`,
    DEVICE_TYPES: CONFIG.DEVICE_TYPES,
    DEVICE_TYPES_RP: CONFIG.DEVICE_TYPES_RP,
    DEVICE_TYPES_RQ: CONFIG.DEVICE_TYPES_RQ,
}

