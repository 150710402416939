import React from "react";
import "./OnboardingFooter.css";
import {useLayout} from "../../_rightcom/layout/core";
const OnboardingFooter = (props:any) => {
	const {classes} = useLayout()

	return (<div className='border footer d-flex flex-lg-column'
	             id='kt_footer'
	             style={{border: 'solid 0.75px #ddd',background:'#FAFAFA 0% 0% no-repeat padding-box'}}>
		{/* begin::Container */}
		<div
			className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
			style={{minHeight:80}}
		>
			{/* begin::Copyright */}
			{/*<div className='text-dark order-2 order-md-1'>*/}
			{/*	<span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>*/}
			{/*	<a href='https://rightcom.com' className='text-gray-800 text-hover-primary'>*/}
			{/*		RightCom*/}
			{/*	</a>*/}
			{/*</div>*/}
			{/* end::Copyright */}

			{/* begin::Nav */}
			<ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
				<li className='menu-item'>
					<a href='#' className='menu-link ps-0 pe-2'>
						{new Date().getFullYear()} &copy; RightCom
					</a>
				</li>
				<li className='menu-item'>
					<a href='#' className='menu-link pe-0 pe-2'>
						Explore RightCom XP
					</a>
				</li>
				<li className='menu-item'>
					<a href='#' className='menu-link pe-0'>
						More Products
					</a>
				</li>
				<li className='menu-item'>
					<a href='#' className='menu-link pe-0'>
						Help and Information
					</a>
				</li>
			</ul>
			<ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>

				<li className='menu-item'>
					<a href='#' className='menu-link pe-0'>
						A Product of
						<img src="/RightCom.svg" alt="Rightcom" style={{height:16,marginLeft:5, marginBottom:-1}}/>
					</a>
				</li>
			</ul>
			{/* end::Nav */}
		</div>
		{/* end::Container */}
	</div>);
};
export default OnboardingFooter;
