/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'

import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../../../../_rightcom/layout/components/header/HeaderWrapper.css';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as helpers from "../../../../utils/helpers"

import Select from 'react-select'
import { isNotEmpty } from '../../../../_rightcom/helpers';
import { useLang } from '../../../../_rightcom/i18n/Metronici18n'
import {useLocation} from "react-router-dom";

const AppFilter = (props: any) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const alias = params.get('alias');
  const app_id = params.get('app_id');
  const sid = params.get('sid');
  const subdomain = params.get('subdomain');
  const user = params.get('user');

  const item = props.item
  const intl = useIntl()
  const currentLang = useLang()

  const branches = [
    { value: 'Maryland', label: 'Chocolate' },
    { value: 'Texas', label: 'Strawberry' },
    { value: 'New York', label: 'Vanilla' }
  ]

  const dateRangeLocalFr = {
    'format': 'DD/MM/YYYY',
    separator: ' - ',
    applyLabel: 'Appliquer',
    cancelLabel: 'Annuler',
    fromLabel: 'De',
    toLabel: 'À',
    customRangeLabel: 'Personnalisé',
    weekLabel: 'S',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    firstDay: 1,
  };

  const dateRangeLocal = {
    'format': 'MM-DD-YYYY',
    separator: ' - '
  };

  const session_date_from = sessionStorage.getItem("date_from");
  const session_date_to = sessionStorage.getItem("date_to");

  const dateRangeSetting = {
    value: new Date().toISOString(),
    locale: currentLang === 'fr' ? dateRangeLocalFr : dateRangeLocal,
    startDate: session_date_from ? moment(session_date_from, 'YYYY-MM-DD') : moment(),
    endDate: session_date_to ? moment(session_date_to, 'YYYY-MM-DD') : moment(),
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 50,
      borderColor: '#E4E4E4'
    })
  };

  const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  const offices = reduxStore.officeList;

  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(session_date_from);
  const [toDate, setToDate] = useState<any>(session_date_to);

  const dispatch = useDispatch();

  const handleChangeBranch = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBranch(e.target.value);
  };

  useEffect(()=>{
    helpers.getOfficesList(dispatch, {
      "filter": {
        "alias": alias,
        "app_id": app_id,
        "sid": sid,
        "subdomain": subdomain,
        "user": user
      }
    })
  }, [alias, app_id, sid, subdomain, user, dispatch])

  useEffect(()=>{
    if(isNotEmpty(selectedBranch)) {
      let data = {
        "filter": {
          "app_code": ["RP","RQ"],
          "office": selectedBranch,
          "from": fromDate ? fromDate : moment().format('YYYY-MM-DD').toString(),
          "to": toDate ? toDate : moment().format('YYYY-MM-DD').toString(),
          "alias": alias,
          "app_id": app_id,
          "sid": sid,
          "subdomain": subdomain,
          "user": user
        }
      };
      helpers.getHomeData(dispatch, data)
    }
  }, [selectedBranch, alias, app_id, sid, subdomain, user])

  const handleDateRange=(e:any, dateRange:any)=>{
    //console.log(dateRange.startDate, dateRange.endDate)
    setFromDate(dateRange.startDate.format('YYYY-MM-DD'))
    setToDate(dateRange.endDate.format('YYYY-MM-DD'))

    sessionStorage.setItem("date_from", dateRange.startDate.format('YYYY-MM-DD'))
    sessionStorage.setItem("date_to", dateRange.endDate.format('YYYY-MM-DD'))
  }

  useEffect(()=>{
    if(isNotEmpty(fromDate) && isNotEmpty(toDate)) {
      let data = {
        "filter": {
          "app_code": ["RP","RQ"],
          "office": selectedBranch,
          "from": fromDate,
          "to": toDate,
          "alias": alias,
          "app_id": app_id,
          "sid": sid,
          "subdomain": subdomain,
          "user": user
        }
      };
      helpers.getHomeData(dispatch, data)
    }
  }, [fromDate, toDate])

  return (
    <div className="app-container mb-5">
    <div className="row">
    <div className="col-4">
      </div>
      <div className="col-2"> </div>
      <div className='col-2 text-end'>
        <label>{intl.formatMessage({id: 'FILTER.FILTER_BY'})} </label>
      </div> 
      <div className='col-2' data-rc-user-table-filter="form" >
        <select onChange={handleChangeBranch} className="form-select form-selectj fo " data-rc-select2="true" data-placeholder="Select option" data-allow-clear="true" data-rc-user-table-filter="role" data-hide-search="true">          
         <option value="">{intl.formatMessage({id: 'FILTER.BRANCHES_PLACEHOLDER'})}</option>
          {offices.map((office: any, i:any) => {                        
           return (<option key={office.objectId} value={office.objectId}>{office.name}</option>) 
          })}
        </select>
      </div>
      <div className='col-2' >
        <DateRangePicker onApply={handleDateRange} initialSettings={dateRangeSetting}>
          <input type="text" className="form-control form-select form-el  dashboard-inputt" />
        </DateRangePicker>
      </div>
    </div>
   </div> 
  )
}

export {AppFilter}
