import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
	HeaderNotificationsMenu,
	HeaderUserMenu,
	QuickLinks,
	Search,
	ThemeModeSwitcher,
} from '../../../partials'
import {useLayout} from '../../core'
import './HeaderWrapper.css'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useSelector, shallowEqual} from "react-redux";

const toolbarButtonMarginClass = 'ms-1 ms-lg-8 ms-4',
	toolbarButtonHeightClass = 'w-73px h-30px w-md-40px h-md-40px',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
	toolbarButtonIconSizeClass = 'svg-icon-1'

const handleLangSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
	setLanguage(e.target.value)
};

const Topbar: FC = () => {
	const currentLang = useLang()
	const {config} = useLayout()
	// TODO TOPBAT RIGHT ICONS
	const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  const username = reduxStore.username;
	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>
			{/* Search */}
			{/*<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>*/}
			{/*  <Search />*/}
			{/*</div>*/}
			{/* Activities */}
			{/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
			{/*  /!* begin::Drawer toggle *!/*/}
			{/*  <div*/}
			{/*    className={clsx(*/}
			{/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
			{/*      toolbarButtonHeightClass*/}
			{/*    )}*/}
			{/*    id='kt_activities_toggle'*/}
			{/*  >*/}
			{/*    <KTSVG*/}
			{/*      path='/media/icons/duotune/general/gen032.svg'*/}
			{/*      className={toolbarButtonIconSizeClass}*/}
			{/*    />*/}
			{/*  </div>*/}
			{/*  /!* end::Drawer toggle *!/*/}
			{/*</div>*/}

			{/* NOTIFICATIONS */}
			{/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
			{/*  /!* begin::Menu- wrapper *!/*/}
			{/*  <div*/}
			{/*    className={clsx(*/}
			{/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
			{/*      toolbarButtonHeightClass*/}
			{/*    )}*/}
			{/*    data-rc-menu-trigger='click'*/}
			{/*    data-rc-menu-attach='parent'*/}
			{/*    data-rc-menu-placement='bottom-end'*/}
			{/*    data-rc-menu-flip='bottom'*/}
			{/*  >*/}
			{/*    <KTSVG*/}
			{/*      path='/media/icons/duotune/general/gen022.svg'*/}
			{/*      className={toolbarButtonIconSizeClass}*/}
			{/*    />*/}
			{/*  </div>*/}
			{/*  <HeaderNotificationsMenu />*/}
			{/*  /!* end::Menu wrapper *!/*/}
			{/*</div>*/}

			{/* CHAT */}
			{/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
			{/*  /!* begin::Menu wrapper *!/*/}
			{/*  <div*/}
			{/*    className={clsx(*/}
			{/*      'btn btn-icon btn-active-light-primary btn-custom position-relative',*/}
			{/*      toolbarButtonHeightClass*/}
			{/*    )}*/}
			{/*    id='kt_drawer_chat_toggle'*/}
			{/*  >*/}
			{/*    <KTSVG*/}
			{/*      path='/media/icons/duotune/communication/com004.svg'*/}
			{/*      className={toolbarButtonIconSizeClass}*/}
			{/*    />*/}

			{/*    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>*/}
			{/*  </div>*/}
			{/*  /!* end::Menu wrapper *!/*/}
			{/*</div>*/}

			{/* begin::Theme mode */}
			
			{/* end::Theme mode */}
			{/* begin::User */}
			<div
				className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
				id='rc_header_user_menu_toggle'
			>
				{/* begin::Toggle */}
				<div className={"text-end me-2 d-none d-sm-inline-block ff"}>
					<div>{username}</div>
				</div>
				{/* end::Toggle */}
			</div>
			{/* end::User */}

			<div className="d-flex align-items-center">
				<select onChange={handleLangSelect} className="custom-select ff" required>
				<option selected={currentLang === 'en'} value="en">🇺🇸&emsp;ENGLISH</option>
				<option selected={currentLang === 'fr'}  value="fr">🇫🇷&emsp;FRENCH</option>
				</select>
			</div>

			{/* Quick links */}
			<div id={'applauncher'} className={clsx('d-flex align-items-center', toolbarButtonMarginClass,"bg-ju px-4 mer")}>
				{/* begin::Menu wrapper */}
				<div
					className={clsx(
						'btn btn-icon btn-custom',
						toolbarButtonHeightClass
					)}
					data-rc-menu-trigger='click'
					data-rc-menu-attach='parent'
					data-rc-menu-placement='bottom-end'
					data-rc-menu-flip='bottom'
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
					     className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
						<path
							d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
					</svg>
				</div>
				<QuickLinks/>
				{/* end::Menu wrapper */}
			</div>


			{/* begin::Aside Toggler */}
			{/*{config.header.left === 'menu' && (*/}
			{/*	<div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>*/}
			{/*		<div*/}
			{/*			className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'*/}
			{/*			id='kt_header_menu_mobile_toggle'*/}
			{/*		>*/}
			{/*			<KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1'/>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*)}*/}
		</div>
	)
}

export {Topbar}
