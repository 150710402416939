/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import './HeaderWrapper.css'

import {useSelector, shallowEqual} from "react-redux";

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  const companies = reduxStore.companies;

  return (
    <div
      id='_rc_header'
      className={clsx('header', classes.header.join(' '),
          'align-items-stretch border-bottom border-end border-start')}
      {...attributes.headerMenu}
      style={{
        borderBottom: 'solid 1px #ddd !important',
        borderBottomLeftRadius:0,
        borderBottomRightRadius:0,
        height:'70px',
      }}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between '
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        

        {/*{aside.display && (*/}
          <div className='d-flex m-2 mar flex-grow-1 flex-lg-grow-0' >
          <select style={{'width': '18vw'}} className="form-select form-selectj fo " aria-label="Default select example">
            {companies.map((company: any, i:any) => {                        
                return (<option key={i} value={company}>{company}</option>) 
            })}
          </select>
          </div>
        {/*)}*/}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center ps-5' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
