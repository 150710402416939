import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_rightcom/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_rightcom/assets/css/style.rtl.css'
 **/
import './_rightcom/assets/sass/style.scss'
import './_rightcom/assets/sass/plugins.scss'
import './_rightcom/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import { DateRangePicker, DateRange } from 'react-date-range';
import {Provider} from 'react-redux'
import store from './redux/store'
import * as helpers from "../src/utils/helpers"
import './custom.css'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic RightDesk mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject RightDesk interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
        {/*<AuthProvider>*/}
          <AppRoutes/>
        {/*</AuthProvider>*/}
        </MetronicI18nProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </Provider>
    
  )
}
