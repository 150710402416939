const initialState = {
    defaultStatisticRightPlayer: {
        name: 'RightPlayer', 
        code: 'RP',
        data: {
            total_device: 0,
            total_device_working: 0,
            total_ticket: 0
        }
    },

    defaultStatisticRightQ: {
        name: 'RightQ', 
        code: 'RQ',
        data: {
            total_device: 0,
            total_device_working: 0,
            total_ticket: 0
        }
    },

    statisticRightPlayer: {
        name: 'RightPlayer', 
        code: 'RP',
        data: {
            total_device: 0,
            total_device_working: 0,
            total_ticket: 0
        }
    },

    statisticRightQ: {
        name: 'RightQ', 
        code: 'RQ',
        data: {
            total_device: 0,
            total_device_working: 0,
            total_ticket: 0
        }
    },

    homeData: [
        {
            name: 'RightPlayer', 
            code: 'RP',
            data: [
                {
                    type: 'windows',
                    total_device: 0,
                    total_device_working: 0,
                    total_ticket: 0
                },
                {
                    type: 'android',
                    total_device: 0,
                    total_device_working: 0,
                    total_ticket: 0
                },
                {
                    type: 'linux',
                    total_device: 0,
                    total_device_working: 0,
                    total_ticket: 0
                },
            ]
        },
        {
            name: 'RightQ', 
            code: 'RQ',
            data: [
                {
                    type: 'kiosk',
                    total_device: 0,
                    total_device_working: 0,
                    total_ticket: 0
                },
                {
                    type: 'welcomer',
                    total_device: 0,
                    total_device_working: 0,
                    total_ticket: 0
                }
            ]
        }
    ],

    deviceData: [],
    deviceRpData: [],
    deviceRqData: [],
    officeList: [],
    companies: [],
    username: '',
};

function rootReducer(state = initialState, action) {
    if (!state) {
        return null;
    } else {
        return {...state, [action.key]: action.payload};
    }
}

export default rootReducer;
