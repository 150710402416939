import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn, TableProps } from 'react-data-table-component';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './RightPlayer.css'

import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as helpers from "../../utils/helpers"
import { AnyIfEmpty } from 'react-redux';
import { isNotEmpty } from '../../_rightcom/helpers';
import {useIntl} from 'react-intl'
import {useLang} from '../../_rightcom/i18n/Metronici18n'
import {useLocation} from "react-router-dom";

const constant = require('../../config')

interface Data {
    id:number;
    req: number;
    print: number;
    ldo: string;
    ltd: string;
    stat: string;
    add: string;
    dt: string;
    dn: string;
    b: string;
  }

const AllTable: React.FC = () => {

  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const alias = params.get('alias');
  const app_id = params.get('app_id');
  const sid = params.get('sid');
  const subdomain = params.get('subdomain');
  const user = params.get('user');

  const intl = useIntl()
  const currentLang = useLang()

  const columns: TableColumn<Data>[] = [
  
    {
      name: intl.formatMessage({id: 'COLUMN.BRANCH'}),
      selector: (row: Data) => row.b,
      sortable: true,
      style: {
        textAlign: 'center',
        font: '600 16px/20px Source Sans Pro',
        color: '#373737',
        opacity: 1
      },
    },
    {
      name: intl.formatMessage({id: 'COLUMN.ADDRESS'}),
      selector: (row: Data) => row.add,
      sortable: true,
    },
    {
        name: intl.formatMessage({id: 'COLUMN.DEVICE_TYPE'}),
        cell: (row) => {
            switch (row.dt) {
              case 'welcomer':
                return <img src="media/types/welcomer.png" alt='yo' className="icontype"  />;
                case 'kiosk':
                    return <img src="media/types/kiosk.png" alt='yo' className="icontype"  />;
                    case 'android':
                        return <img src="media/types/android.png" alt='yo' className="icontype" />;
                        case 'windows':
                            return <img src="media/types/windows.png" alt='yo' className="icontype" />;
                            case 'linux':
                                return <img src="media/types/linux.png" alt='yo' className="icontype"  />;
              default:
                return null;
            }
          },
      },
      {
        name: intl.formatMessage({id: 'COLUMN.DEVICE_NAME'}),
        selector: (row: Data) => row.dn,
        sortable: true,
      },
      {
        name: intl.formatMessage({id: 'COLUMN.STATUS'}),
        selector: (row: Data) => row.stat,
        sortable: true,
      },
      {
        name: intl.formatMessage({id: 'COLUMN.LAST_DATE_ONLINE'}),
        selector: (row: Data) => row.ldo,
        sortable: true,
        
      },
      {
        name: intl.formatMessage({id: 'COLUMN.LAST_TICKET_DATE'}),
        selector: (row: Data) => row.ltd,
        sortable: true,
      },
      {
        name: intl.formatMessage({id: 'COLUMN.REQUESTED'}),
        selector: (row: Data) => row.req,
        sortable: true,
      },
        {
        name: intl.formatMessage({id: 'COLUMN.DISPLAYED'}),
        selector: (row: Data) => row.print,
        sortable: true,
      },
  ];

  const rowStyles = {
      backgroundColor: '#F8F8F8',
      
  };
    
  const conditionalRowStyles: TableProps<Data>['conditionalRowStyles'] = [
    {
      when: (row) => row.id % 2 === 0, // Appliquer un style différent pour chaque deuxième ligne
      style: rowStyles,
    },
  ];

  const customStyles = {
    
    rows: {
      style: {
        fontFamily: 'Arial, sans-serif',
        textAlign:'center',
        marginLeft: '0px',
      }
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontFamily: 'Source Sans Pro, sans-serif',
        color: '#808080',
        fontWeight:'bold',
        height:'5vh',
        width:'5vw',
        textAlign: 'center',
        justifyContent: 'center',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    cells: {
        style: {
            width:'12vw', 
            textAlign: 'center',
            justifyContent: 'center',
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    
  };

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage({id: 'DATATABLE_MESSAGES.ROWS_PER_PAGE_TEXT'}),
    rangeSeparatorText: intl.formatMessage({id: 'DATATABLE_MESSAGES.RANGE_SEPARATOR_TEXT'}),
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: intl.formatMessage({id: 'DATATABLE_MESSAGES.SELECT_ALL_ROWS_ITEM_TEXT'}),
  }

  const noDataComponent = <div style={{padding: '24px'}}> {intl.formatMessage({id: 'DATATABLE_MESSAGES.NO_DATA_COMPONENT'})} </div> ;

  const progressComponent = <div style={{ fontSize: '24px', fontWeight: 700, padding: '24px' }}> {intl.formatMessage({id: 'DATATABLE_MESSAGES.LOADING'})} </div> ;

  const mapData = (lists: any) => {
    return lists.map((device: any) => {
      return {
        id: device.id,
        req: device.requested,
        print: device.total_ticket_displayed,
        dt: device.device_type,
        dn: device.device_name,
        add: device.address,
        ltd: device.last_ticket_date,
        ldo: device.last_date_online,
        b: device.office,
        stat: device.working ? intl.formatMessage({id: 'COLUMN.DEVICE_WORKING'}) : intl.formatMessage({id: 'COLUMN.DEVICE_NOT_WORKING'})
      }
    })
  }

  const dateRangeLocalFr = {
    'format': 'DD/MM/YYYY',
      separator: ' - ',
      applyLabel: 'Appliquer',
      cancelLabel: 'Annuler',
      fromLabel: 'De',
      toLabel: 'À',
      customRangeLabel: 'Personnalisé',
      weekLabel: 'S',
      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      firstDay: 1,
  };

  const dateRangeLocal = {
    'format': 'MM-DD-YYYY',
      separator: ' - '
  };

  const session_date_from = sessionStorage.getItem("date_from");
  const session_date_to = sessionStorage.getItem("date_to");

  const dateRangeSetting = {
    value: new Date().toISOString(),
    locale: currentLang === 'fr' ? dateRangeLocalFr : dateRangeLocal,
    startDate: session_date_from ? moment(session_date_from, 'YYYY-MM-DD') : moment(),
    endDate: session_date_to ? moment(session_date_to, 'YYYY-MM-DD') : moment(),
  }
   
  const [searchText, setSearchText] = useState<string>("");
  const [selectedTool, setSelectedTool] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>("");
  const [fromDate, setFromDate] = useState<any>(session_date_from);
  const [toDate, setToDate] = useState<any>(session_date_to);

  const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  const offices = reduxStore.officeList;

  const dispatch = useDispatch();
  useEffect(() => {
    let params =  {
      "filter": {
        "app_code": "RP",
        "from": isNotEmpty(fromDate) ? fromDate : moment().format('YYYY-MM-DD').toString(),
        "to": isNotEmpty(toDate) ? toDate : moment().format('YYYY-MM-DD').toString(),
        "type": isNotEmpty(selectedTool) ? selectedTool : null,
        "office": isNotEmpty(selectedBranch) ? selectedBranch : null,
        "alias": alias,
        "app_id": app_id,
        "sid": sid,
        "subdomain": subdomain,
        "user": user
      }
    }
      helpers.getDeviceData(dispatch, params)
  }, [selectedTool, selectedBranch, fromDate, toDate, alias, app_id, sid, subdomain, user])

  useEffect(()=>{
    helpers.getOfficesList(dispatch, {
      "filter": {
        "alias": alias,
        "app_id": app_id,
        "sid": sid,
        "subdomain": subdomain,
        "user": user
      }
    })
  }, [alias, app_id, sid, subdomain, user, dispatch])

  const lists = reduxStore.deviceData
  const data = mapData(lists)

  const filteredData = data.filter(
    (row: any) => row.dn.toLowerCase().includes(searchText.toLowerCase()) 
  )

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleChangeTool = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //console.log('handleChangeTool', e.target.value)
    setSelectedTool(e.target.value);
  };

  const handleChangeBranch = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //console.log('handleChangeBranch', e.target.value)
    setSelectedBranch(e.target.value);
  };

  const handleDateRange=(e:any,j:any)=>{
    //console.log('handleDateRange', j.startDate, j.endDate)
    setFromDate(j.startDate)
    setToDate(j.endDate)

    sessionStorage.setItem("date_from", j.startDate.format('YYYY-MM-DD'))
    sessionStorage.setItem("date_to", j.endDate.format('YYYY-MM-DD'))
  }
    
  return (
    <>
      <div className="app-container mb-5">
    <div className="row">
    <div className="col-4">
      {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
      <span className=" mt-4 position-absolute ms-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="11.998" height="12" viewBox="0 0 11.998 12">
  <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M11.835,10.375,9.5,8.038a.562.562,0,0,0-.4-.164H8.718a4.872,4.872,0,1,0-.844.844V9.1a.562.562,0,0,0,.164.4l2.336,2.336a.56.56,0,0,0,.794,0l.663-.663A.565.565,0,0,0,11.835,10.375Zm-6.96-2.5a3,3,0,1,1,3-3A3,3,0,0,1,4.875,7.874Z" fill="#bbb"/>
</svg>

      </span>
      {/*end::Svg Icon*/}
      <input type="text" data-rc-user-table-filter="search"  value={searchText}
   onChange={handleChangeSearch}  className="form-control form-control-solid myw ps-14 yu " placeholder={intl.formatMessage({id: 'FILTER.SEARCH_PLACEHOLDER'})} />
          </div>
          <div className="col-2 text-end"> <label>{intl.formatMessage({id: 'FILTER.FILTER_BY'})} </label></div>
          <div className='col-2'>
            <select className="form-select form-selectj  fo " value={selectedTool} onChange={handleChangeTool} >
              <option value="">{intl.formatMessage({id: 'FILTER.TOOLS_PLACEHOLDER'})}</option>
              {constant.DEVICE_TYPES_RP.map((os: any, i:any) => {                        
                return (<option key={i} value={os.value}>{os.name}</option>) 
              })}
            </select>
          </div> 
          <div className='col-2' data-rc-user-table-filter="form" >
            <select className="form-select form-selectj fo " value={selectedBranch} onChange={handleChangeBranch} data-rc-select2="true" data-placeholder="Select option" data-allow-clear="true" data-rc-user-table-filter="role" data-hide-search="true">
              <option value="">{intl.formatMessage({id: 'FILTER.BRANCHES_PLACEHOLDER'})}</option>
              {offices.map((office: any, i:any) => {                        
                return (<option key={office.objectId} value={office.objectId}>{office.name}</option>) 
              })}
            </select>
          </div>
          <div className='col-2' >
            <DateRangePicker onApply={handleDateRange} initialSettings={dateRangeSetting} >
            <input type="text" className="form-control form-select form-el  dashboard-inputt"  />
            </DateRangePicker>
          </div>
    </div>
   </div> 
  
  <DataTable 
    columns={columns} 
    pagination 
    data={filteredData} 
    conditionalRowStyles={conditionalRowStyles} 
    customStyles={customStyles} 
    paginationComponentOptions={paginationComponentOptions} 
    noDataComponent={noDataComponent}
    progressComponent={progressComponent}/></>
  )
}

export default AllTable
