import React, {useContext, useEffect, useState} from "react";
import {Route, Routes,useLocation} from "react-router-dom";
import AllDevices from "../AllDevices";
import RightPlayer from "../RightPlayer";
import {useIntl} from "react-intl";
import moment from 'moment';
import './MainWrapper.css'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function MainWrapper() {
    const intl = useIntl()
    const location = useLocation();
  
    const allowedPaths = "/alldevices|/rightq|/rightplayer"; 
    const regex = new RegExp(`^(${allowedPaths})$`); 
  const isAllowedPath = regex.test(location.pathname);
  const dateRangeSetting = {
    value: new Date().toISOString(),
    locale: {
        'format': 'MMMM DD YYYY'
    },
    startDate: moment(),
    endDate: moment()
  }

  return (
   <>
   <div className="app-container mb-5">
    <div className="row">
    <div className="col-4">
      {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
      <span className=" mt-4 position-absolute ms-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="11.998" height="12" viewBox="0 0 11.998 12">
  <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M11.835,10.375,9.5,8.038a.562.562,0,0,0-.4-.164H8.718a4.872,4.872,0,1,0-.844.844V9.1a.562.562,0,0,0,.164.4l2.336,2.336a.56.56,0,0,0,.794,0l.663-.663A.565.565,0,0,0,11.835,10.375Zm-6.96-2.5a3,3,0,1,1,3-3A3,3,0,0,1,4.875,7.874Z" fill="#bbb"/>
</svg>

      </span>
      {/*end::Svg Icon*/}
      <input type="text" data-rc-user-table-filter="search"  className="form-control form-control-solid w-250px ps-14 yu " placeholder="SEARCH" />
          </div>
          <div className="col-2"> <label>Filter by </label></div>
          <div className='col-2'>
            <select className="form-select form-selectj  fo " >
              <option value="tool">TOOLS</option>
              <option value="one">One</option>
            </select>
          </div> 
          <div className='col-2' data-rc-user-table-filter="form" >
          <select className="form-select form-selectj fo " data-rc-select2="true" data-placeholder="Select option" data-allow-clear="true" data-rc-user-table-filter="role" data-hide-search="true">
            <option value="0">BRANCHES</option>
            <option value="calavi">Calavi</option>
          </select>
          </div>
          <div className='col-2' >
          <DateRangePicker initialSettings={dateRangeSetting}>
            <input type="text" className="form-control form-selectj dashboard-input" />
          </DateRangePicker>
          </div>
          
    </div>
   </div> 
        
    </>
  )
}

export default MainWrapper