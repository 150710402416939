// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 570px)  {
    .daterangepicker.opensright:before {
        left: 342px !important;
    }
    
    .daterangepicker.opensright:after {
        left: 343px !important;
    }
}

.text-rightcom {
    color: #3586ea !important;
}`, "",{"version":3,"sources":["webpack://./src/custom.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["@media only screen and (min-width: 570px)  {\n    .daterangepicker.opensright:before {\n        left: 342px !important;\n    }\n    \n    .daterangepicker.opensright:after {\n        left: 343px !important;\n    }\n}\n\n.text-rightcom {\n    color: #3586ea !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
