/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState,useEffect} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link, useLocation} from "react-router-dom";
import {AsideMenu} from './AsideMenu'
import Card from '../card/cardDefault'
import {useSelector, shallowEqual} from "react-redux";
import {useIntl} from 'react-intl'

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const {aside} = config
  const [label, setLabel]=useState('Home')
  const location = useLocation()
  const intl = useIntl()

 
  const allowedPaths = "/alldevices|/dashboard|/rightplayer"; 
  const allowedPath = "/alldevices|/dashboard|/rightq"; 
  const regex = new RegExp(`^(${allowedPaths})$`);
  const regex1 = new RegExp(`^(${allowedPath})$`);

  const isAllowedPath = regex.test(location.pathname);
  const isAllowedPath1 = regex1.test(location.pathname);

  const minimize = () => {
    asideRef.current?.classList.add('animating')
    setTimeout(() => {
      asideRef.current?.classList.remove('animating')
    }, 300)
  }
  const imageUrl = "media/logos/rp.png";
  const imageUrl2 = "media/logos/rq.png";

  const reduxStore = useSelector((state:any) => state.rootReducer, shallowEqual);
  console.log(reduxStore)
  console.log(reduxStore.statisticRightPlayer)
  console.log(reduxStore.statisticRightQ)
  const statisticRightPlayer = reduxStore.statisticRightPlayer;
  const statisticRightQ = reduxStore.statisticRightQ;
  const statisticRightPlayerData = statisticRightPlayer === undefined ? reduxStore.defaultStatisticRightPlayer : statisticRightPlayer
  const statisticRightQData = statisticRightQ === undefined ? reduxStore.defaultStatisticRightQ : statisticRightQ

  return (
   <div>
     {location.pathname === ('/rightq') && <div style={{marginBottom:40}}> <h3>RightQ</h3></div>}
     {location.pathname === ('/rightplayer') && <div style={{marginBottom:40}}> <h3>RightPlayer</h3></div>}
     {location.pathname === ('/alldevices') && <div style={{marginBottom:40}}> <h3>{intl.formatMessage({id: 'SIDEBAR.ALLDEVICES'})}</h3></div>}
     {location.pathname === ('/dashboard') && <div style={{marginBottom:40}}> <h3>{intl.formatMessage({id: 'SIDEBAR.HOME'})}</h3></div>}
     {isAllowedPath && <Card prodName='RightPlayer' prodImg={imageUrl} data={statisticRightPlayerData}/> }
     {isAllowedPath1 && <Card prodName='RightQ'prodImg={imageUrl2} data={statisticRightQData}/> }
   </div>
    )
}

export {AsideDefault}
