import React, {useState, useEffect} from 'react'
import "./401.css";
import {PageTitle} from "../../_rightcom/layout/core";
import {useIntl} from "react-intl";

const Page401 = (props: any) => {
	const intl = useIntl()

	const translates: any = {
        "fr": {
            "description": "Veuillez cliquer sur le bouton ci-dessous pour revenir à la page Analytics.",
            "btnLabel": "ALLER À LA PAGE ANALYTIQUE",
        },
        "en": {
            "description": "Please click the button below to return to Analytics page.",
            "btnLabel": "GO TO ANALYTICS PAGE"
        },
        "pt": {
            "description": "Clique no botão abaixo para retornar à página do Analytics.",
            "btnLabel": "IR PARA A PÁGINA DE ANALÍTICA"
        }
    }

	

	const [selectedLang, setSelectedLang] = useState<any>('en');

	const handleChangLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedLang(e.target.value);
	  };

	return (
		<div className="row d-flex justify-content-between p-20 unauthorized">
        <div className="row" >
        	<div className="col-6">
				<img src="rightcom-logo.svg" alt='yo' className="img-fluid"  />
        	</div>
			<div className="col-6 align-self-center d-flex justify-content-end">
				<span className="LanguageDropdown" >
				<select onChange={handleChangLang} data-flag="true" id="langs" className="langSelect">
					<option value="en" id="en">🇺🇸 ENGLISH</option>
					<option value="fr" id="fr">🇫🇷 FRANÇAIS</option>
					<option value="pt" id="pt"> 🇵🇹 PORTUGUÊS</option>
				</select>
				</span>
			</div>
        </div>



        <div className="row">
            <div className="col-sm-6 text-center mx-auto">
                <p className="text-center">
					<img className="my-20" src="unauthorized.svg"/>
				</p>
                <p className="pleaseClickText text-center mx-auto" id="description">{translates[selectedLang]['description']}</p>
                <a href="/"><p className="text-center"><button className="btn BtnHomePage" id="btnLabel">{translates[selectedLang]['btnLabel']}</button></p></a>
            </div>
        </div>
</div>
	);
};
export default Page401;
