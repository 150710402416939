import {applyMiddleware, combineReducers, legacy_createStore as createStore} from "redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import {createLogger} from "redux-logger"

const logger = createLogger({});

const customMiddleware = (store) => (next) => (action) => {
    next(action)
}

const store = createStore(
    combineReducers({rootReducer}),
    composeWithDevTools(
        applyMiddleware(
            thunk,
            logger,
            customMiddleware,
        )
    )
);

export default store;
