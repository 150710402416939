// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daterangepicker .drp-calendar td.active.start-date {
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
}

.daterangepicker .drp-calendar td.active {
    border-radius: 50% !important;
}

.css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.h-50px {
    height: 50px !important;
}

.border-e4 {
    border-color: #E4E4E4 !important;
}

.daterangepicker .calendar-table td {
    width: 31px !important;
    height: 31px !important;
}

.dashboard-input {
    border-color: #E4E4E4 !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-size: 1.075rem !important;
    height: 50px !important;
    border-radius: 3px !important;
    color: #808080 !important;
    /*font-family: "Source Sans Pro, Bold" !important;*/
}

.dashboard-select {
    border-color: #E4E4E4 !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-size: 1.075rem !important;
    height: 50px !important;
    border-radius: 3px !important;
    color: #808080 !important;
    border-color: #E4E4E4 !important;
    /*font-family: "Source Sans Pro, Bold" !important;*/
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,2CAA2C;AAC/C;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;IAChC,oCAAoC;IACpC,2BAA2B;IAC3B,8BAA8B;IAC9B,uBAAuB;IACvB,6BAA6B;IAC7B,yBAAyB;IACzB,mDAAmD;AACvD;;AAEA;IACI,gCAAgC;IAChC,oCAAoC;IACpC,2BAA2B;IAC3B,8BAA8B;IAC9B,uBAAuB;IACvB,6BAA6B;IAC7B,yBAAyB;IACzB,gCAAgC;IAChC,mDAAmD;AACvD","sourcesContent":[".daterangepicker .drp-calendar td.active.start-date {\n    border-top-right-radius: none !important;\n    border-bottom-right-radius: none !important;\n}\n\n.daterangepicker .drp-calendar td.active {\n    border-radius: 50% !important;\n}\n\n.css-1u9des2-indicatorSeparator {\n    width: 0px !important;\n}\n\n.h-50px {\n    height: 50px !important;\n}\n\n.border-e4 {\n    border-color: #E4E4E4 !important;\n}\n\n.daterangepicker .calendar-table td {\n    width: 31px !important;\n    height: 31px !important;\n}\n\n.dashboard-input {\n    border-color: #E4E4E4 !important;\n    text-transform: uppercase !important;\n    font-weight: 700 !important;\n    font-size: 1.075rem !important;\n    height: 50px !important;\n    border-radius: 3px !important;\n    color: #808080 !important;\n    /*font-family: \"Source Sans Pro, Bold\" !important;*/\n}\n\n.dashboard-select {\n    border-color: #E4E4E4 !important;\n    text-transform: uppercase !important;\n    font-weight: 700 !important;\n    font-size: 1.075rem !important;\n    height: 50px !important;\n    border-radius: 3px !important;\n    color: #808080 !important;\n    border-color: #E4E4E4 !important;\n    /*font-family: \"Source Sans Pro, Bold\" !important;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
