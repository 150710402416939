import React from "react";
import "./Onboarding.css";
import OnboardingHeader from "../OnboardingHeader";
import OnboardingFooter from "../OnboardingFooter";
import {PageTitle} from "../../_rightcom/layout/core";
import {useIntl} from "react-intl";

const Onboarding = (props: any) => {
	const intl = useIntl()

	return (
		<div className={"Onboarding"}>

			<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Onboading'})}</PageTitle>

			<OnboardingHeader/>
			<main>
				<div className="progress">
					{/*// @ts-ignore*/}
					<div className="progress-bar bg-success" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"/>
				</div>
				<div className="container-fluid">
					<div className="container">
						<div className="row">
							<div className="col-sm-1"/>
							<div className="col">
								{/*TODO PUT ONBOADING STEPS HERE*/}
								PUT ONBOADING STEPS HERE
							</div>
							<div className="col-sm-1"/>
						</div>
					</div>
				</div>
			</main>
			<OnboardingFooter/>
		</div>
	);
};
export default Onboarding;
