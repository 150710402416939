import React from "react";
import "./SamplePage.css";
import {PageTitle} from "../../_rightcom/layout/core";
import {useIntl} from "react-intl";

const SamplePage = (props) => {
	const intl = useIntl()
	return (
		<div className={"SamplePage"}>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Sample Page'})}</PageTitle>
			<div className='card card-custom'>
				<div className='card-body p-9'>
					SamplePage
					<div>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam consequuntur cum,
						excepturi hic ipsa iure maxime nesciunt nobis officia, quasi saepe sapiente temporibus veniam
						voluptates. Animi illum impedit ipsum.
					</div>
				</div>
			</div>
		</div>
	);
};
export default SamplePage;
